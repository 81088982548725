import 'magnific-popup';

// Apple
var loaded = false;
document.addEventListener( 'musickitloaded', function () {
	if ( !loaded ) {
		loaded = true;
		let AmkPlaylists = [];
		let AmkAlbums = ['1488410268'];
		// Create a new SMEAppleMusic object with your parameters
		let sm = new SMEAppleMusic( {
			ae: {
				ae: '13d53b9ec1a982f4c75b5575d6c06f20d0071cf21f83f82eabbb8f7a14ed0a60',
				brand_id: '4259876',
				segment_id: '839081',
				activities: '{"actions":{"presave":29039},"mailing_list_optins":{"a0S1p00000TLuYPEA1":29040}}'
			},
			am: {
				dev_token: '',
				save_mode: 'library',
				custom_playlist_name: '',
				resources: {
					playlists: AmkPlaylists,
					albums: AmkAlbums
				}
			},
			sf: {
				form: '50785',
				default_mailing_list: 'a0S1p00000TLuYPEA1'
			},
			smf: {
				campaign_id: '50785',
				campaign_key: '8282d66195ed8cb3d7e9aa0fa3c0febf'
			}
		} );

		document.getElementById( 'apple_music_form' ).addEventListener( 'submit', function ( e ) {
			e.preventDefault();
			// Grab the email address value
			const email = document.getElementById( 'apple_music_email_address' ).value;
			// Pass the email address and opt in status
			// This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
			sm.doActions( email, true ).then( function ( res ) {
				$.magnificPopup.close();
				$.magnificPopup.open( {
					items: {
						src: '#thanks',
						type: 'inline'
					}
				} );
			} ).catch( function ( err ) {
				alert( 'An error has occurred!' );
			} );
		} );
	}
} );

$( document ).ready( function ( $ ) {
	// Card flip
	$( '#card' ).flip( {
		trigger: 'manual'
	} );

	$( '#card .front' ).on( 'click', function ( e ) {
		$( '#card' ).flip( true );
	} );

	$( '#card' ).on( 'flip:done', function () {
		const flip = $( '#card' ).data( 'flip-model' );
		if ( flip.isFlipped ) {
			$( '.share, #presave' ).fadeIn();
		}
	} );

	// Social popup open
	$( '.share>li>a' ).each( function ( index, item ) {
		const link = $( this ).attr( 'href' ).replace( '%url', window.location.href );
		$( this ).attr( 'href', link );
	} );


	$( '.share>li>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		const link = $( this ).attr( 'href' );
		const w = 670;
		const h = 378;
		const y = window.outerHeight / 2 + window.screenY - (
			h / 2
		);
		const x = window.outerWidth / 2 + window.screenX - (
			w / 2
		);
		window.open( link, '_blank', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x );
	} );

	// Pre-save
	$( '.apple-presave' ).on( 'click', function ( e ) {
		e.preventDefault();
		$.magnificPopup.open( {
			items: {
				src: '#apple',
				type: 'inline'
			}
		} );
	} );

	let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function ( callback, form_id ) {
			let hash;
			let hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
			for ( let i = 0; i < hashes.length; i ++ ) {
				hash = hashes[i].split( '=' );
				this.url_vars.push( hash[0] );
				this.url_vars[hash[0]] = hash[1];
			}
			this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
			this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
			this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
			this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
			this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
			if ( typeof callback === 'function' ) {
				callback( this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message );
			}
		}
	};

	SMEPreSave.getURLVars( function ( state, ae_member_id, spotify_user, deezer_user, message ) {
		// Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
		if ( state === 'success' || state === 'thank-you' ) {
			$.magnificPopup.open( {
				items: {
					src: '#thanks',
					type: 'inline'
				}
			} );
		}
	} );
} );